// These are primarily used for react-select

/**
 * Dropdown options for different connection software.
 *
 * @type {Array<Object>}
 * @property {string} value - The value representing the connection software.
 * @property {string} label - The label to be displayed for the connection software.
 */
export const connectionSoftwareOptions = [
    { value: 'splashtop', label: 'Splashtop' },
    { value: 'unique vpn', label: 'Unique VPN' },
    { value: 'cloud', label: 'Cloud' }
];

/**
 * Dropdown options for different IT departments.
 *
 * @type {Array<Object>}
 * @property {string} value - The value representing the IT segment.
 * @property {string} label - The label to be displayed for the IT segment.
 */
export const itDeptOptions = [
    { value: 'hd', label: 'Help Desk' },
    { value: 'newsc/admin', label: 'New SC/Admin' },
    { value: 'nclt', label: 'NCLT' }
];

/**
 * Dropdown options for different operating systems.
 *
 * @type {Array<Object>}
 * @property {string} value - The value representing the OS.
 * @property {string} label - The label to be displayed for the OS.
 */
export const operatingSystemOptions = [
    { value: 'windows vista', label: 'Windows Vista' },
    { value: 'windows 7', label: 'Windows 7' },
    { value: 'windows 8', label: 'Windows 8' },
    { value: 'windows 10', label: 'Windows 10' },
    { value: 'windows 11', label: 'Windows 11' },
    { value: 'windows ce', label: 'Windows CE' },
    { value: 'windows home server', label: 'Windows Home Server' },
    { value: 'windows server 2008', label: 'Windows Server 2008' },
    { value: 'windows server 2008 r2', label: 'Windows Server 2008 R2' },
    { value: 'windows server 2012', label: 'Windows Server 2012' },
    { value: 'windows server 2012 r2', label: 'Windows Server 2012 R2' },
    { value: 'windows server 2016', label: 'Windows Server 2016' },
    { value: 'windows server 2019', label: 'Windows Server 2019' },
    { value: 'windows server 2022', label: 'Windows Server 2022' },
    { value: 'macos x leopard', label: 'MacOS X Leopard' },
    { value: 'macos x snow leopard', label: 'MacOS X Snow Leopard' },
    { value: 'macos x lion', label: 'MacOS X Lion' },
    { value: 'macos x mountain lion', label: 'MacOS X Mountain Lion' },
    { value: 'macos x mavericks', label: 'MacOS X Mavericks' },
    { value: 'macos x yosemite', label: 'MacOS X Yosemite' },
    { value: 'macos x el capitan', label: 'MacOS X El Capitan' },
    { value: 'macos sierra', label: 'MacOS Sierra' },
    { value: 'macos high sierra', label: 'MacOS High Sierra' },
    { value: 'macos mojave', label: 'MacOS Mojave' },
    { value: 'macos catalina', label: 'MacOS Catalina' },
    { value: 'macos 11 big sur', label: 'MacOS 11 Big Sur' },
    { value: 'macos 12 monterrey', label: 'MacOS 12 Monterrey' },
    { value: 'macos 13 ventura', label: 'MacOS 13 Ventura' }
];

/**
 * Dropdown options for boolean values (true or false).
 *
 * @constant
 * @type {Array<Object>}
 * @property {boolean} value - The boolean value.
 * @property {string} label - The label to be displayed for the boolean value.
 */
export const booleanOptions = [
    {
        value: true,
        label: 'Yes'
    },
    {
        value: false,
        label: 'No'
    }
];

/**
 * Dropdown options for user roles.
 *
 * @constant
 * @type {Array<Object>}
 * @property {boolean} value - The boolean value.
 * @property {string} label - The label to be displayed for the boolean value.
 */
export const userRoleOptions = [
    {
        value: '65c54c7f19a02a87988b158e',
        label: 'Help Desk'
    },
    {
        value: '65c54c7f19a02a87988b158f',
        label: 'SC Install/Admin Tech'
    },
    {
        value: '65c54c7f19a02a87988b1590',
        label: 'Phone Tech'
    },
    {
        value: '65c54c7f19a02a87988b1592',
        label: 'Developer'
    },
    {
        value: '65c54c7f19a02a87988b1593',
        label: 'Office Install'
    },
    {
        value: '65c54c7f19a02a87988b1594',
        label: 'Manager'
    },
    {
        value: '65c54c7f19a02a87988b1595',
        label: 'Team Leader'
    },
    {
        value: '65c54c7f19a02a87988b1596',
        label: 'TOS'
    },
    {
        value: '65c54c7f19a02a87988b1597',
        label: 'Operations'
    },
    {
        value: '65c54c7f19a02a87988b1598',
        label: 'Legal'
    },
    {
        value: '65c54c7f19a02a87988b1599',
        label: 'COS'
    }
];

/**
 * Dropdown options for HIPAA incompliance reasons.
 *
 * @constant
 * @type {Array<Object>}
 * @property {boolean} value - The boolean value.
 * @property {string} label - The label to be displayed for the boolean value.
 */
export const hipaaIncompliantReasonOptions = [
    {
        value: 'credentials',
        label: 'Weak credentials'
    },
    {
        value: 'os',
        label: 'Out-of-date operating system'
    },
    {
        value: 'av',
        label: 'No antivirus'
    },
    {
        value: 'vpn',
        label: 'Unique VPN without addendum'
    }
];

/**
 * Dropdown options for office note types.
 *
 * @constant
 * @type {Array<Object>}
 * @property {boolean} value - The boolean value.
 * @property {string} label - The label to be displayed for the boolean value.
 */
export const officeNoteTypeOptions = [
    {
        value: 'active',
        label: 'Active Offices'
    },
    {
        value: 'retired',
        label: 'Retired Offices'
    },
    {
        value: 'all',
        label: 'All Offices'
    }
];

/**
 * Dropdown options for new SC card types.
 *
 * @constant
 * @type {Array<Object>}
 * @property {boolean} value - The boolean value.
 * @property {string} label - The label to be displayed for the boolean value.
 */
export const newScCardTypeOptions = [
    {
        value: 'active',
        label: 'Active Vetted'
    },
    {
        value: 'retired',
        label: 'Retired Vetted'
    },
    {
        value: 'awaitingAssignment',
        label: 'Cleared by IT'
    },
    {
        value: 'expired',
        label: 'Expired Cards'
    },
    {
        value: 'all',
        label: 'All Vetted'
    }
];

/**
 * Dropdown options for new SC card softwares.
 *
 * @constant
 * @type {Array<Object>}
 * @property {boolean} value - The boolean value.
 * @property {string} label - The label to be displayed for the boolean value.
 */
export const newScCardSoftwareOptions = [
    {
        label: 'ABLEDent',
        value: 'abeldent'
    },
    {
        label: 'Curve',
        value: 'curve'
    },
    {
        label: 'Eaglesoft',
        value: 'eaglesoft'
    },
    {
        label: 'Easy Dental',
        value: 'easyDental'
    },
    {
        label: 'Endo Vision',
        value: 'endoVision'
    },
    {
        label: 'Daisy',
        value: 'daisy'
    },
    {
        label: 'Dental Vision',
        value: 'dentalVision'
    },
    {
        label: 'Denticon/Planet DDS',
        value: 'denticon'
    },
    {
        label: 'Dentimax',
        value: 'dentimax'
    },
    {
        label: 'Dentech',
        value: 'dentech'
    },
    {
        label: 'Dentrix',
        value: 'dentrix'
    },
    {
        label: 'Dentrix Ascend',
        value: 'dentrixAscend'
    },
    {
        label: 'Dentrix Enterprise',
        value: 'dentrixEnterprise'
    },

    {
        label: 'DSN',
        value: 'dsn'
    },
    {
        label: 'Mac Practice',
        value: 'macPractice'
    },
    {
        label: 'Medical Billing',
        value: 'medicalBilling'
    },
    {
        label: 'Mogo',
        value: 'mogo'
    },
    {
        label: 'Ortho Software',
        value: 'orthoSoftware'
    },
    {
        label: 'Open Dental',
        value: 'openDental'
    },
    {
        label: 'PBS',
        value: 'pbs'
    },
    {
        label: 'Practice Works',
        value: 'practiceWorks'
    },
    {
        label: 'Primident',
        value: 'primident'
    },
    {
        label: 'QSI',
        value: 'qsi'
    },
    {
        label: 'Softdent',
        value: 'softdent'
    },
    {
        label: 'TOPS',
        value: 'tops'
    },
    {
        label: 'Umbie',
        value: 'umbie'
    },
    {
        label: 'WinOMS',
        value: 'winoms'
    },
    {
        label: 'XLDent',
        value: 'xldent'
    }
];

/**
 * Dropdown options for time period options.
 *
 * @constant
 * @type {Array<Object>}
 * @property {boolean} value - The boolean value.
 * @property {string} label - The label to be displayed for the boolean value.
 */
export const timePeriodOptions = [
    {
        value: 'thisWeek',
        label: 'This Week'
    },
    {
        value: 'lastWeek',
        label: 'Last Week'
    },
    {
        value: 'thisMonth',
        label: 'This Month'
    },
    {
        value: 'lastMonth',
        label: 'Last Month'
    },
    {
        value: 'thisQuarter',
        label: 'This Quarter'
    },
    {
        value: 'lastQuarter',
        label: 'Last Quarter'
    },
    {
        value: 'thisYear',
        label: 'This Year'
    },
    {
        value: 'lastYear',
        label: 'Last Year'
    },
    {
        value: 'allTime',
        label: 'All Time'
    }
];

/**
 * Dropdown options for different New SC workflow types.
 *
 * @type {Array<Object>}
 * @property {string} value - The value representing the workflow type.
 * @property {string} label - The label to be displayed for the workflow type.
 */
export const newScWorkflowTypeOptions = [
    { value: 'standard', label: 'Standard' },
    { value: 'rcc-bloom', label: 'DSO SC (Stateside)' },
    { value: 'rcc-stateside', label: 'DSO eSC (Bloomies)' },
    { value: 'ivsc', label: 'IVSC' }
];
