import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    booleanOptions,
    operatingSystemOptions
} from '../../config/dropdown-options.js';
import SubmitButton from '../common/SubmitButton.jsx';
import useComputers from '../../hooks/useComputers.js';
import { createComputerFormFields } from '../../config/form-fields.js';
import createComputerValidation from '../../validation/create-computer-validation.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import {
    addComputer,
    updateComputer as updateComputerRedux
} from '../../redux/slices/office-slice.js';
import useUsers from '../../hooks/useUsers.js';

/**
 * CreateUpdateComputerForm component for rendering the form to create a new computer or update an existing one.
 *
 * @component
 * @returns {JSX.Element} - The rendered CreateUpdateComputerForm component.
 */
const CreateUpdateComputerForm = ({
    objectToUpdate,
    setShowEdit,
    setShowCreate,
    officeId,
    officeSysName
}) => {
    const dispatch = useDispatch();

    const {
        error: computerError,
        loading: computerLoading,
        computer,
        createComputer,
        updateComputer,
        getAllSplashtopComputers
    } = useComputers();

    const {
        error: usersError,
        loading: usersLoading,
        getAllUsers
    } = useUsers();

    const [userDropdownOptions, setUserDropdownOptions] = useState([]);
    const [
        splashtopComputerDropdownOptions,
        setSplashtopComputerDropdownOptions
    ] = useState([]);

    const formik = useFormik({
        initialValues: objectToUpdate
            ? {
                  ...objectToUpdate,
                  tsPlusLicenseId: objectToUpdate.tsPlusLicenseId
                      ? objectToUpdate.tsPlusLicenseId.id
                      : null
              }
            : {
                  ...createComputerFormFields,
                  computerSysName: `${officeSysName}.` // auto populate office systematic name for ease
              },
        validationSchema: createComputerValidation,
        onSubmit: async (values) => {
            if (objectToUpdate) {
                const updatedComputer = await updateComputer(
                    objectToUpdate.id,
                    values,
                    objectToUpdate,
                    officeSysName
                );
                if (updatedComputer) {
                    dispatch(
                        updateComputerRedux({
                            ...updatedComputer,
                            tsPlusLicenseId: objectToUpdate.tsPlusLicenseId,
                            credentials: objectToUpdate.credentials
                        })
                    );
                    setShowEdit(false);
                }
            } else {
                const newComputer = await createComputer(values, officeSysName);
                if (newComputer) {
                    dispatch(addComputer({ ...newComputer, credentials: [] }));
                }
            }
        }
    });

    useEffect(() => {
        console.log('This is data', objectToUpdate);
        formik.setFieldValue('officeId', officeId);
    }, [officeId]);

    useEffect(() => {
        /**
         * Checks if the error passed back from submission is because the computer systematic name is taken.
         * If it is, sets the proper field error
         */
        const callCheckSysName = async () => {
            if (
                computerError &&
                computerError.message ===
                    'Computer systematic name already exists'
            ) {
                formik.setFieldError(
                    'computerSysName',
                    'That systematic name is taken, please select another'
                );
            }

            if (
                computerError &&
                computerError.message.includes(
                    'Computer systematic name must contain the office systematic name:'
                )
            ) {
                formik.setFieldError(
                    'computerSysName',
                    `The computer systematic name must contain the office systematic name: ${officeSysName}`
                );
            }
        };

        callCheckSysName();
    }, [computerError]);

    useEffect(() => {
        const getUsers = async () => {
            let returnedUsers = await getAllUsers();
            returnedUsers = returnedUsers.map((u) => {
                // eslint-disable-next-line no-param-reassign
                u = {
                    value: u.email,
                    label: u.email
                };
                return u;
            });
            setUserDropdownOptions(returnedUsers);
        };

        const getSplashtopComputers = async () => {
            const returnedComputers = await getAllSplashtopComputers();
            returnedComputers.unshift({
                value: null,
                label: 'None'
            });
            setSplashtopComputerDropdownOptions(returnedComputers);
        };

        getUsers();
        getSplashtopComputers();
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <table className="office-table">
                    <tbody>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="computerSysName">
                                    Computer Systematic Name:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="computerSysName"
                                    type="text"
                                    className="office-computer-edit-input"
                                    placeholder="e.g. ut.syracuse.anderson.server1"
                                    style={{
                                        color: formik.errors.computerSysName
                                            ? 'red'
                                            : 'black'
                                    }}
                                    {...formik.getFieldProps('computerSysName')}
                                />
                            </td>
                            {formik.touched.computerSysName &&
                            formik.errors.computerSysName ? (
                                <ErrorMessage
                                    message={formik.errors.computerSysName}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="computerName">
                                    Computer Name:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="computerName"
                                    type="text"
                                    placeholder="e.g. WIN1289809"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('computerName')}
                                />
                            </td>
                            {formik.touched.computerName &&
                            formik.errors.computerName ? (
                                <ErrorMessage
                                    autofocus
                                    message={formik.errors.computerName}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="OS">Operating System:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <Select
                                    id="OS"
                                    name="OS"
                                    options={operatingSystemOptions}
                                    value={operatingSystemOptions.find(
                                        (option) =>
                                            option.value === formik.values.OS
                                    )}
                                    onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                            'OS',
                                            selectedOption
                                                ? selectedOption.value
                                                : null
                                        )
                                    }
                                />
                            </td>
                            {formik.touched.OS && formik.errors.OS ? (
                                <ErrorMessage message={formik.errors.OS} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="avInstalled">
                                    Is antivirus installed?:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <Select
                                    id="avInstalled"
                                    name="avInstalled"
                                    options={booleanOptions}
                                    value={booleanOptions.find(
                                        (option) =>
                                            option.value ===
                                            formik.values.avInstalled
                                    )}
                                    onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                            'avInstalled',
                                            selectedOption.value
                                        )
                                    }
                                />
                            </td>
                            {formik.touched.avInstalled &&
                            formik.errors.avInstalled ? (
                                <ErrorMessage
                                    message={formik.errors.avInstalled}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="avName">Antivirus Name:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="avName"
                                    type="text"
                                    placeholder="e.g. Norton"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('avName')}
                                />
                            </td>
                            {formik.touched.avName && formik.errors.avName ? (
                                <ErrorMessage message={formik.errors.avName} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="domain">Domain:</label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="domain"
                                    type="text"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('domain')}
                                />
                            </td>
                            {formik.touched.domain && formik.errors.domain ? (
                                <ErrorMessage message={formik.errors.domain} />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="location">
                                    Location in office:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="location"
                                    type="text"
                                    placeholder="e.g. Back room"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('location')}
                                />
                            </td>
                            {formik.touched.location &&
                            formik.errors.location ? (
                                <ErrorMessage
                                    message={formik.errors.location}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="syncroStatus">
                                    Syncro Status:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <input
                                    id="syncroStatus"
                                    type="text"
                                    placeholder="e.g. Installed"
                                    className="office-computer-edit-input"
                                    {...formik.getFieldProps('syncroStatus')}
                                />
                            </td>
                            {formik.touched.syncroStatus &&
                            formik.errors.syncroStatus ? (
                                <ErrorMessage
                                    message={formik.errors.syncroStatus}
                                />
                            ) : null}
                        </tr>
                        {objectToUpdate && (
                            <>
                                {' '}
                                <tr>
                                    <td className="office-table-left-cell">
                                        <label htmlFor="installTech">
                                            Install Tech:
                                        </label>
                                    </td>
                                    <td className="office-table-right-cell">
                                        {usersLoading && <LoadingSpinner />}
                                        {usersError && (
                                            <ErrorMessage
                                                message={usersError.message}
                                            />
                                        )}
                                        {userDropdownOptions.length > 0 && (
                                            <Select
                                                id="installTech"
                                                name="installTech"
                                                options={userDropdownOptions}
                                                value={userDropdownOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        formik.values
                                                            .installTech
                                                )}
                                                onChange={(selectedOption) =>
                                                    formik.setFieldValue(
                                                        'installTech',
                                                        selectedOption
                                                            ? selectedOption.value
                                                            : null
                                                    )
                                                }
                                            />
                                        )}
                                    </td>
                                    {formik.touched.installTech &&
                                    formik.errors.installTech ? (
                                        <ErrorMessage
                                            message={formik.errors.installTech}
                                        />
                                    ) : null}
                                </tr>
                                <tr>
                                    <td className="office-table-left-cell">
                                        <label htmlFor="installDate">
                                            Install Date:
                                        </label>
                                    </td>
                                    <td className="office-table-right-cell">
                                        <input
                                            id="installDate"
                                            type="date"
                                            className="office-computer-edit-input"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            defaultValue={
                                                formik.values.installDate
                                                    ? new Date(
                                                          formik.values.installDate
                                                      )
                                                          .toISOString()
                                                          .substring(0, 10)
                                                    : null
                                            }
                                        />
                                    </td>
                                    {formik.touched.installDate &&
                                    formik.errors.installDate ? (
                                        <ErrorMessage
                                            message={formik.errors.installDate}
                                        />
                                    ) : null}
                                </tr>
                            </>
                        )}
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="splashtopId">
                                    Linked Splashtop computer:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                {/* {computerLoading && <LoadingSpinner />}
                                {computerError && (
                                    <ErrorMessage
                                        message={computerError.message}
                                    />
                                )} */}
                                {splashtopComputerDropdownOptions.length >
                                    0 && (
                                    <Select
                                        id="splashtopId"
                                        name="splashtopId"
                                        options={
                                            splashtopComputerDropdownOptions
                                        }
                                        value={splashtopComputerDropdownOptions.find(
                                            (option) =>
                                                option.value ===
                                                formik.values.splashtopId
                                        )}
                                        onChange={(selectedOption) =>
                                            formik.setFieldValue(
                                                'splashtopId',
                                                selectedOption
                                                    ? selectedOption.value
                                                    : null
                                            )
                                        }
                                    />
                                )}
                            </td>
                            {formik.touched.splashtopId &&
                            formik.errors.splashtopId ? (
                                <ErrorMessage
                                    message={formik.errors.splashtopId}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td className="office-table-left-cell">
                                <label htmlFor="isITOnly">
                                    Is this an IT only connection?:
                                </label>
                            </td>
                            <td className="office-table-right-cell">
                                <Select
                                    id="isITOnly"
                                    name="isITOnly"
                                    options={booleanOptions}
                                    value={booleanOptions.find(
                                        (option) =>
                                            option.value ===
                                            formik.values.isITOnly
                                    )}
                                    onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                            'isITOnly',
                                            selectedOption.value
                                        )
                                    }
                                />
                            </td>
                            {formik.touched.isITOnly &&
                            formik.errors.isITOnly ? (
                                <ErrorMessage
                                    message={formik.errors.isITOnly}
                                />
                            ) : null}
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderRight: '1px #DCDCDC solid',
                                    padding: '.5em'
                                }}>
                                <label htmlFor="note">Note:</label>
                            </td>
                            <td style={{ padding: '.5em' }}>
                                <textarea
                                    id="note"
                                    placeholder="e.g. This is a special computer"
                                    className="office-computer-edit-input"
                                    style={{
                                        height: '10em',
                                        paddingTop: '.5em'
                                    }}
                                    {...formik.getFieldProps('note')}
                                />
                            </td>
                            {formik.touched.note && formik.errors.note ? (
                                <ErrorMessage message={formik.errors.note} />
                            ) : null}
                        </tr>
                    </tbody>
                </table>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <SubmitButton
                        label="Submit"
                        style={{ marginLeft: '30px' }}
                    />
                    {objectToUpdate ? (
                        <button
                            className="submit-btn"
                            style={{ marginLeft: '30px' }}
                            onClick={() => setShowEdit(false)}>
                            Cancel
                        </button>
                    ) : (
                        <button
                            className="submit-btn"
                            style={{ marginLeft: '30px' }}
                            onClick={() => setShowCreate(false)}>
                            Cancel
                        </button>
                    )}
                </div>
            </form>
            <br />
            <br />
            {computerLoading && <LoadingSpinner />}
            {Object.keys(formik.errors).length !== 0 &&
            Object.keys(formik.touched).length !== 0 ? (
                <ErrorMessage message="There are form errors above, please fix." />
            ) : null}
            {computerError &&
            computerError.message !==
                'Computer systematic name already exists' ? (
                <ErrorMessage message={computerError.message} />
            ) : null}
            {computer && objectToUpdate && (
                <SuccessMessage message="Computer successfully updated" />
            )}
            {computer && !objectToUpdate && (
                <SuccessMessage message="Computer successfully created" />
            )}
        </>
    );
};

CreateUpdateComputerForm.propTypes = {
    objectToUpdate: PropTypes.object,
    setShowEdit: PropTypes.func,
    setShowCreate: PropTypes.func,
    officeId: PropTypes.string.isRequired,
    officeSysName: PropTypes.string.isRequired
};

export default CreateUpdateComputerForm;
